






































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import { Select, Option, Checkbox, CheckboxGroup, Tree } from 'element-ui'
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Tree)

@Component
export default class extends Vue {
  $constants: any
  info = {
    name: '',
    remark: '',
    infIds: [] as any,
    deviceGroupIds: [] as any
  }
  menuLists = [] as any
  menuLists2 = [] as any
  isGate = false
  disableGate = false
  gateinfo = {} as any
  loading = false
  isHover = false
  checkAll = false
  defaultCheckedKeys = [] as any
  defaultProps = {
    children: 'subInterfaces',
    label: 'name',
    disabled: this.disabledFn
  }
  // 半选中的父节点
  halfCheckedKeys = [] as any

  // 获取设备组数据
  get deviceGroups() {
    return CommonModule.deviceGroups
  }

  beforeCreate() {
    CommonModule.GetDeviceGroups()
  }
  mounted() {
    this.$api.people
      .getMenuLists()
      .then((res: any) => {
        if (res.data.success) {
          this.menuLists2 = res.data.data
          this.menuLists2.map((p: any, index: any) => {
            if (p.name === '门岗') {
              this.gateinfo = p
            }
            if (p.name === '物业管理') {
              p.subInterfaces.map((f: any, i: any) => {
                if (f.name === '报修管理') {
                  p.subInterfaces.splice(i, 1)
                }
              })
            }
          })
        }
      })
      .then(() => {
        if (this.$route.query.id) {
          this.getRoleDetail()
        }
      })
    this.$api.people
      .getMenuLists({ type: 1 })
      .then((res: any) => {
        if (res.data.success) {
          this.menuLists = res.data.data
          this.menuLists.map((p: any, index: any) => {
            if (p.name === '门岗') {
              this.menuLists.splice(index, 1)
            }
            if (p.name === '物业管理') {
              p.subInterfaces.map((f: any, i: any) => {
                if (f.name === '报修管理') {
                  p.subInterfaces.splice(i, 1)
                }
              })
            }
          })
        }
      })
      .then(() => {
        if (this.$route.query.id) {
          this.getRoleDetail()
        }
      })
  }

  gateChange(e: any) {
    if (e) {
      this.info.infIds.push(this.gateinfo.id)
    } else {
      this.info.infIds = []
    }
  }

  disabledFn() {
    if (this.isGate) {
      return true
    } else return false
  }

  handleCheckChange() {
    const res = (this.$refs.tree as any).getCheckedNodes()
    const arr = [] as any
    const arr2 = [] as any
    res.forEach((item: any) => {
      arr.push(item.id)
    })
    ;(this.$refs.tree2 as any).setCheckedKeys(arr)
    const res2 = (this.$refs.tree2 as any).getCheckedNodes()
    res2.forEach((item: any) => {
      arr2.push(item.id)
    })
    if (res2 > 0 || res.length > 0) {
      this.isGate = false
      this.disableGate = true
    } else if (res2.length === 0 && res.length === 0) {
      this.disableGate = false
    }
    this.halfCheckedKeys = (this.$refs.tree2 as any).getHalfCheckedKeys()
    this.info.infIds = arr2.concat(this.halfCheckedKeys)
  }
  getRoleDetail() {
    this.loading = true
    this.$api.people
      .getRoleDetail(this.$route.query.id)
      .then((res: any) => {
        this.loading = false
        if (res.data.success) {
          this.info = res.data.data
          if (this.info.infIds.includes(this.gateinfo.id)) {
            this.isGate = true
          }
          this.checkAll =
            this.info.deviceGroupIds.length === this.deviceGroups.length
          const tempParent = [] as any
          const tempArr = [] as any
          this.menuLists.forEach((parent: any) => {
            if (parent.pid === 0) {
              tempParent.push(parent.id)
            }
          })
          if (tempParent.length > 0) {
            res.data.data.infIds.forEach((item: any) => {
              if (!tempParent.includes(item)) {
                tempArr.push(item)
              }
            })
          }
          this.defaultCheckedKeys = tempArr
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  save() {
    ;(this.$refs['info'] as any).validate((valid: any) => {
      if (valid) {
        this.loading = true
        if (this.$route.query.id) {
          // 编辑
          this.$api.people
            .editRole(this.info)
            .then((res: any) => {
              this.loading = false
              if (res.data.success) {
                this.$message.success(res.data.msg)
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          this.$api.people
            .addRole(this.info)
            .then((res: any) => {
              this.loading = false
              if (res.data.success) {
                this.$message.success(res.data.msg)
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      }
    })
  }

  handleCheckAllChange(val: boolean) {
    this.info.deviceGroupIds = val
      ? this.deviceGroups.map((v: any) => {
          return v.id
        })
      : []
  }

  handleCheckedDoorsChange(value: any) {
    const checkedCount = value.length
    this.checkAll = checkedCount === this.deviceGroups.length
  }

  // 返回
  goback() {
    this.$router.push('/people/role-list')
  }
}
